import * as yup from 'yup';

export const store_admin_create_rules = yup.object({
    tax_id: yup.string().max(8, "統一編號至多為8碼"),
    store_name: yup.string().max(100, "店家名稱至多為100個字").required('店家名稱尚未填寫'),
    store_name_en: yup.string().max(100, "店家名稱（英文）至多為100個字").required('店家名稱（英文）尚未填寫'),
    invoice_options: yup.string().required("是否開立收據尚未選擇"),
    store_type: yup.string().required('店家類型尚未選擇'),
    // store_subtype: yup.string().required('店家子類型尚未選擇'),
    town: yup.string().required('行政區尚未選擇'),
    address: yup.string().max(300, "店家地址至多為300個字").required('店家地址尚未填寫'),
    address_en: yup.string().max(300, "店家地址（英文）至多為300個字").required('店家地址（英文）尚未填寫'),
    // lat: yup.number().typeError("必須為數字").min(22, "地圖緯度最小為22").max(25, "地圖緯度最大為25").required('地圖緯度尚未填寫'),
    // lng: yup.number().typeError("必須為數字").min(120, "地圖經度最小為120").max(122, "地圖經度最大為122").required('地圖經度尚未填寫'),
    lat: yup.number().typeError("必須為數字").min(0, "地圖緯度最小為0").max(25, "地圖緯度最大為25"),
    lng: yup.number().typeError("必須為數字").min(0, "地圖經度最小為0").max(122, "地圖經度最大為122"),
    business_district: yup.string().required('商圈尚未選擇'),
    phone: yup.string().max(100, "店家電話至多為100個字").required('店家電話尚未填寫'),
    email: yup.string().email('店家Email格式錯誤').max(200, "店家Email至多為200個字").required('店家Email尚未填寫'),
    contact_person: yup.string().max(50, "聯絡人姓名至多為50個字").required('聯絡人姓名尚未填寫'),
    contact_phone: yup.string().max(100, "聯絡人電話至多為100個字").required('聯絡人電話尚未填寫'),
    web_site: yup.string().max(1000, "網站連結至多為1000個字"),
    facebook: yup.string().max(1000, "FB連結至多為1000個字"),
    line_at_account: yup.string().max(1000, "LINE@帳號至多為1000個字"),
    instagram: yup.string().max(1000, "IG連結至多為1000個字"),
    forutek_url: yup.string().max(1000, "旅行蹤APP網址至多為1000個字"),
    business_time: yup.string().max(300, "營業時間至多為300個字").required('營業時間尚未填寫'),
    business_time_en: yup.string().max(300, "營業時間（英文）至多為300個字"),
    introduce: yup.string().required('店家簡介尚未填寫'),
    top_options: yup.string().required("是否置頂尚未選擇"),
    top_ranking: yup.number().typeError("必須為數字")
        .when(
            "top_options", 
            (top_options: any, schema: any) => {
                if (top_options == 0 || top_options == 1 ) {
                    return schema.min(1, '置頂排序最小值為1').max(99999, '置頂排序最大值為99999');
                }
                return schema;
            }),
    status_options: yup.string().required("啟用狀態尚未選擇"),
    start_date: yup.date().typeError("發佈期間起始日期尚未選擇")
        .when(
            'status_options',
            (status_options: any, schema: any) => {
                if (status_options !== "" && status_options !== undefined) {
                    return schema.required("發佈期間起始日期尚未選擇");
                }
                return schema;
            }),
    end_date: yup.date().typeError("發佈期間結束日期尚未選擇")
        .when(
            'start_date',
            (start_date: any, schema: any) => {
                if (start_date !== "" && start_date !== undefined && start_date !== null && start_date.toString() !== "Invalid Date") {
                    const dayAfter = new Date(new Date(start_date).getTime());
                    return schema.min(dayAfter, '結束日期不可早於起始日期').required("發佈期間結束日期尚未選擇");
                }
                return schema;
            }),
})

export const store_admin_modify_rules = yup.object({
    tax_id: yup.string().max(8, "統一編號至多為8碼"),
    store_name: yup.string().max(100, "店家名稱至多為100個字").required('店家名稱尚未填寫'),
    store_name_en: yup.string().max(100, "店家名稱（英文）至多為100個字").required('店家名稱（英文）尚未填寫'),
    invoice_options: yup.string().required("是否開立收據尚未選擇"),
    store_type: yup.string().required('店家類型尚未選擇'),
    // store_subtype: yup.string().required('店家子類型尚未選擇'),
    town: yup.string().required('行政區尚未選擇'),
    address: yup.string().max(300, "店家地址至多為300個字").required('店家地址尚未填寫'),
    address_en: yup.string().max(300, "店家地址（英文）至多為300個字").required('店家地址（英文）尚未填寫'),
    // lat: yup.number().typeError("必須為數字").min(22, "地圖緯度最小為22").max(25, "地圖緯度最大為25").required('地圖緯度尚未填寫'),
    // lng: yup.number().typeError("必須為數字").min(120, "地圖經度最小為120").max(122, "地圖經度最大為122").required('地圖經度尚未填寫'),
    lat: yup.number().typeError("必須為數字").min(0, "地圖緯度最小為0").max(25, "地圖緯度最大為25"),
    lng: yup.number().typeError("必須為數字").min(0, "地圖經度最小為0").max(122, "地圖經度最大為122"),
    business_district: yup.string().required('商圈尚未選擇'),
    phone: yup.string().max(100, "店家電話至多為100個字").required('店家電話尚未填寫'),
    email: yup.string().email('店家Email格式錯誤').max(200, "店家Email至多為200個字").required('店家Email尚未填寫'),
    contact_person: yup.string().max(50, "聯絡人姓名至多為50個字").required('聯絡人姓名尚未填寫'),
    contact_phone: yup.string().max(100, "聯絡人電話至多為100個字").required('聯絡人電話尚未填寫'),
    web_site: yup.string().max(1000, "網站連結至多為1000個字"),
    facebook: yup.string().max(1000, "FB連結至多為1000個字"),
    line_at_account: yup.string().max(1000, "LINE@帳號至多為1000個字"),
    instagram: yup.string().max(1000, "IG連結至多為1000個字"),
    forutek_url: yup.string().max(1000, "旅行蹤APP網址至多為1000個字"),
    business_time: yup.string().max(300, "營業時間至多為300個字").required('營業時間尚未填寫'),
    business_time_en: yup.string().max(300, "營業時間（英文）至多為300個字"),
    introduce: yup.string().required('店家簡介尚未填寫'),
    top_options: yup.string().required("是否置頂尚未選擇"),
    top_ranking: yup.number().typeError("必須為數字")
        .when(
            "top_options", 
            (top_options: any, schema: any) => {
                if (top_options == 0 || top_options == 1 ) {
                    return schema.min(1, '置頂排序最小值為1').max(99999, '置頂排序最大值為99999');
                }
                return schema;
            }),
    status_options: yup.string().oneOf(['0', '1'], "啟用狀態尚未選擇").required("啟用狀態尚未選擇"),
    start_date: yup.date().typeError("發佈期間起始日期尚未選擇")
        .when(
            'status_options',
            (status_options: any, schema: any) => {
                if (status_options !== "" && status_options !== undefined) {
                    return schema.required("發佈期間起始日期尚未選擇");
                }
                return schema;
            })
        .when(
            'status_options',
            (status_options: any, schema: any) => {
                if (status_options !== "" && status_options !== undefined) {
                    return schema.required("發佈期間結束日期尚未選擇");
                }
                return schema;
            }),
    end_date: yup.date().typeError("發佈期間結束日期尚未選擇")
        .when(
            'start_date',
            (start_date: any, schema: any) => {
                if (start_date !== "" && start_date !== undefined && start_date !== null && start_date.toString() !== "Invalid Date") {
                    const dayAfter = new Date(new Date(start_date).getTime());
                    return schema.min(dayAfter, '結束日期不可早於起始日期').required("發佈期間結束日期尚未選擇");
                }
                return schema;
            }),
})

export const store_admin_validate_rules = yup.object({
    tax_id: yup.string().max(8, "統一編號至多為8碼"),
    store_name: yup.string().max(100, "店家名稱至多為100個字").required('店家名稱尚未填寫'),
    store_name_en: yup.string().max(100, "店家名稱（英文）至多為100個字").required('店家名稱（英文）尚未填寫'),
    invoice_options: yup.string().required("是否開立收據尚未選擇"),
    store_type: yup.string().required('店家類型尚未選擇'),
    // store_subtype: yup.string().required('店家子類型尚未選擇'),
    town: yup.string().required('行政區尚未選擇'),
    address: yup.string().max(300, "店家地址至多為300個字").required('店家地址尚未填寫'),
    address_en: yup.string().max(300, "店家地址（英文）至多為300個字").required('店家地址（英文）尚未填寫'),
    // lat: yup.number().typeError("必須為數字").min(22, "地圖緯度最小為22").max(25, "地圖緯度最大為25").required('地圖緯度尚未填寫'),
    // lng: yup.number().typeError("必須為數字").min(120, "地圖經度最小為120").max(122, "地圖經度最大為122").required('地圖經度尚未填寫'),
    lat: yup.number().typeError("必須為數字").min(0, "地圖緯度最小為0").max(25, "地圖緯度最大為25"),
    lng: yup.number().typeError("必須為數字").min(0, "地圖經度最小為0").max(122, "地圖經度最大為122"),
    business_district: yup.string().required('商圈尚未選擇'),
    phone: yup.string().max(100, "店家電話至多為100個字").required('店家電話尚未填寫'),
    email: yup.string().email('店家Email格式錯誤').max(200, "店家Email至多為200個字").required('店家Email尚未填寫'),
    contact_person: yup.string().max(50, "聯絡人姓名至多為50個字").required('聯絡人姓名尚未填寫'),
    contact_phone: yup.string().max(100, "聯絡人電話至多為100個字").required('聯絡人電話尚未填寫'),
    web_site: yup.string().max(1000, "網站連結至多為1000個字"),
    facebook: yup.string().max(1000, "FB連結至多為1000個字"),
    line_at_account: yup.string().max(1000, "LINE@帳號至多為1000個字"),
    instagram: yup.string().max(1000, "IG連結至多為1000個字"),
    forutek_url: yup.string().max(1000, "旅行蹤APP網址至多為1000個字"),
    business_time: yup.string().max(300, "營業時間至多為300個字").required('營業時間尚未填寫'),
    business_time_en: yup.string().max(300, "營業時間（英文）至多為300個字"),
    introduce: yup.string().required('店家簡介尚未填寫'),
    top_options: yup.string().required("是否置頂尚未選擇"),
    top_ranking: yup.number().typeError("必須為數字")
        .when(
            "top_options", 
            (top_options: any, schema: any) => {
                if (top_options == 0 || top_options == 1 ) {
                    return schema.min(1, '置頂排序最小值為1').max(99999, '置頂排序最大值為99999');
                }
                return schema;
            }),
    validate_status_options: yup.string().required("審核結果尚未選擇"),
    start_date: yup.date().typeError("發佈期間起始日期尚未選擇")
        .when(
            'validate_status_options',
            (validate_status_options: any, schema: any) => {
                if (validate_status_options !== "" && validate_status_options !== undefined && validate_status_options !== "0") {
                    return schema.required("發佈期間起始日期尚未選擇");
                }
                return schema;
            })
        .when(
            'validate_status_options',
            (validate_status_options: any, schema: any) => {
                if (validate_status_options !== "" && validate_status_options !== undefined && validate_status_options !== "0") {
                    return schema.required("發佈期間結束日期尚未選擇");
                }
                return schema;
            }),
    end_date: yup.date().typeError("發佈期間結束日期尚未選擇")
        .when(
            'start_date',
            (start_date: any, schema: any) => {
                if (start_date !== "" && start_date !== undefined && start_date !== null && start_date.toString() !== "Invalid Date") {
                    const dayAfter = new Date(new Date(start_date).getTime());
                    return schema.min(dayAfter, '結束日期不可早於起始日期').required("發佈期間結束日期尚未選擇");
                }
                return schema;
            }),
})