import request from "../utils/request";

export function check_tax_id(data: JSON) {
    return request({
        url: "/checkTaxId",
        method: "post",
        data: data
    });
}

export function check_tax_id_admin(data: JSON) {
    return request({
        url: "/checkTaxIdAdmin",
        method: "post",
        data: data
    });
}